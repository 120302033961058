import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';
import {
  BpkTable,
  BpkTableBody,
} from '@skyscanner/backpack-web/bpk-component-table';

import withErrorBoundary from '../ErrorBoundary';
import FooterBlurb from '../common/FooterBlurb';
import MBD from '../common/MBD/MBD';

import Snippet from './Snippet';

import type { InfoSnippetProps } from 'common-types/types/InfoSnippetProps';

import STYLES from './InfoSnippet.module.scss';

const InfoSnippet = ({
  disclaimer,
  header: title,
  isMobile = false,
  snippets,
  subheader,
}: InfoSnippetProps) => {
  if (!snippets?.length) {
    return null;
  }

  return (
    <MBD id="seo_snippet_info" componentName="seo_snippet_info">
      <div className={STYLES.InfoSnippet}>
        <BpkSectionHeader title={title} description={subheader} />
        <BpkTable className={STYLES.Snippets}>
          <BpkTableBody className={STYLES.Snippets__wrap}>
            {snippets.map((snippet, snippetIndex) => (
              <Snippet
                key={snippet.label}
                {...snippet}
                isMobile={isMobile}
                snippetIndex={snippetIndex}
              />
            ))}
          </BpkTableBody>
        </BpkTable>
        {disclaimer && (
          <div className={STYLES.Disclaimer}>
            <FooterBlurb disclaimerCopy={disclaimer} />
          </div>
        )}
      </div>
    </MBD>
  );
};

const InfoSnippetWithErrorBoundary = withErrorBoundary(
  InfoSnippet,
  'info-snippet-component',
);
export default InfoSnippetWithErrorBoundary;
