import type { ReactNode, FC } from 'react';
import {
  useEffect,
  useState,
  Children,
  isValidElement,
  cloneElement,
} from 'react';

import useVertical from './useVertical';

import type { Common as MBDCommon } from 'trackolding/lib/modern';

type MBDProps = {
  id: string;
  componentName: MBDCommon['component_name'];
  mbdProps?: MBDCommon['string_properties'];
};
type MBD = {
  children: ReactNode;
} & MBDProps;

const buildMBDProps = (props: MBDProps, vertical: string) => {
  const { componentName, id, mbdProps } = props || {};
  if (vertical && id && componentName) {
    const customProps = mbdProps || {};
    const mbdParam: MBDCommon = {
      component_name: componentName,
      string_properties: {
        ...customProps,
        vertical,
      },
    };
    return {
      'data-tracking-element-id': id,
      'data-tracking-common-params': JSON.stringify(mbdParam),
    };
  }
  return {};
};
// inject data-tracking-element-id and data-tracking-common-params into the child component
const MBD: FC<MBD> = ({ children, componentName, id, mbdProps = {} }) => {
  const vertical = useVertical();

  const [mbd, setMBD] = useState({});

  useEffect(() => {
    // when vertical, id, componentName exist at the same time, the attr of mbd are injected into child component
    // to make sure the props of the mbd are valid
    // as mbd will be triggered to report load event
    // once data-tracking-element-id, data-tracking-common-params are written to child component
    // So this can ensure that all properties are ready when reporting the load event

    if (vertical && id && componentName) {
      setMBD(
        buildMBDProps(
          {
            id,
            componentName,
            mbdProps,
          },
          vertical,
        ),
      );
    }
  }, [vertical]);

  const wrappedChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { ...mbd });
    }
    return child;
  });

  return <>{wrappedChildren}</>;
};

export default MBD;
