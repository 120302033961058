/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';

import { GLOBAL } from 'saddlebag-browser';
// https://skyscanner.atlassian.net/wiki/spaces/UNDERSTAND/pages/741823398/Conventions+-+Element+Events
// According to the vertical definition of the business glossary. The names/values are different from the definition in falcon.
// so create a map to transform them.
const MAP_TO_BUSINESS_VERTICAL: {
  [key: string]: string;
} = {
  flights: 'flight',
  'car-hire': 'car_hire',
  hotels: 'hotel',
};

function useVertical() {
  const [vertical, setVertical] = useState('');

  useEffect(() => {
    const $window = GLOBAL.getWindow() || {};

    const internal = $window.__internal || {};
    const internalVertical = internal.vertical;
    if (
      typeof internalVertical === 'string' &&
      MAP_TO_BUSINESS_VERTICAL[internalVertical]
    ) {
      setVertical(MAP_TO_BUSINESS_VERTICAL[internalVertical]);
    }

    return () => {};
  }, []);

  return vertical;
}

export default useVertical;
