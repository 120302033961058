import {
  ACTION_TYPE,
  LOAD_STATUS,
  COMPONENT_ACTION,
  COMPONENT_NAME,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';

export const logMiniEventForClick = (
  linkUrl?: string,
  value?: string | string[] | number | null,
) => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.INFO_SNIPPET,
    component_action: COMPONENT_ACTION.INFO_SNIPPET.INFO_SNIPPET_CLICKED,
    link_info: {
      url: linkUrl,
      content: value,
    },
  });
};

export const logMinEventForLoading = () => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_LOADED,
    component_name: COMPONENT_NAME.INFO_SNIPPET,
    load_status: LOAD_STATUS.LOADED,
  });
};
